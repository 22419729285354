import React from 'react';

const DonaldTrump = () => {
  return (
    <div>
      <h2>Donald Trump</h2>
      <p>More to come</p>
    </div>
  );
};

export default DonaldTrump;
