import React from 'react';
import './Home.css';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-text">
        <h1>Welcome to <span class="h-yellow">Lo</span>gical <span class="h-yellow">Fal</span>lacies <span class="h-yellow">App</span>lied</h1>
        <p>Using AI to rapidly analyze public speech for logical fallacies.</p>
      </div>
    </section>
  );
};

export default HeroSection;
