import React from 'react';
import { Link } from 'react-router-dom';
import './Analysis.css';

const PeteButtigieg = ({
  title,
  tableData
}) => {
  return (
    <div className='content'>
      <div className='analysis-container'>
        <h2>{title}</h2>
        <table className="analysis-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Analysis</th>
              <th>Date</th>
              <th>Location</th>
              <th># of LoFals</th>
              <th>Misinformation Index</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index} className={index % 2 === 0 ? 'highlight-row' : ''}>
                <td>
                  <a href={data.titleUrl} target="_blank" rel="noopener noreferrer">
                    {data.title}
                  </a>
                </td>
                <td>
                  <Link to={data.analysisLink}>{data.title} Analysis</Link>
                </td>
                <td>{data.date}</td>
                <td>{data.location}</td>
                <td>{data.loFals}</td>
                <td>{data.misinformationIndex}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PeteButtigieg;
