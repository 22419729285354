import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const AnalysisSection = () => {
  return (
    <section className="team-section">
      <h2>See Our Analysis</h2>
      <div className="team-container">
        <Link to="/analysis/PeteButtigieg" className="no-underline">
          <div className="team-member">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Pete_Buttigieg_official_photo.jpg/192px-Pete_Buttigieg_official_photo.jpg" alt="Pete Buttigeig" />
            <h3>Pete Buttigieg</h3>
            <p>Secretary of Transportion</p>
          </div>
        </Link>
        <Link to="/analysis/DonaldTrump" className="no-underline">
          <div className="team-member">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/189px-Donald_Trump_official_portrait.jpg" alt="Donald Trump" />
            <h3>Donald Trump</h3>
            <p>Former President</p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default AnalysisSection;