import React from 'react';

const highlightedWords = [
    {
      name: 'John Doe',
      quote: 'Delivers the truth',
      type: 'Expert Opinion',
      summary: 'John Doe is an expert in the field and his opinion supports the statement.',
      highlightColor: 'yellow',
    },
    {
      name: 'Jane Smith',
      quote: "came and went without result, we’re working together now",
      type: 'Factual Statement',
      summary: 'Jane Smith provides a fact that supports the main point.',
      highlightColor: 'red',
    },
    {
      name: 'Michael Johnson',
      quote: 'safety, made American ingenuity, the envy of the world',
      type: 'Anecdote',
      summary: 'Michael Johnson shares a personal story that relates to the topic.',
      highlightColor: 'blue',
    },
    {
      name: 'Sara Wilson',
      quote: 'means fewer of those delays and detours and closures',
      type: 'Contradictory Evidence',
      summary: 'Sara Wilson presents an opposing view to consider.',
      highlightColor: 'green',
    },
  ];

const replacePlaceholdersWithQuotes = () => {
  let replacedText = textdata;

  highlightedWords.forEach((wordData, index) => {
    const placeholder = `{${index}}`;
    replacedText = replacedText.replace(placeholder, wordData.quote);
  });

  return replacedText;
};

const pageTitle = "Secretary Buttigieg Delivers Remarks on Bridge Investments in Albany County, New York";

const textdata = `Secretary Buttigieg {0} Remarks on Bridge Investments in Albany County, New York
  Thursday, April 13, 2023
  
  {1}, all putting our money where our mouth is, to make this America's infrastructure decade. And Governor, it’s always good news when I see you. A month ago, as she mentioned, we were in Buffalo celebrating that good news. A month before that, we were with the President celebrating that key piece of funding for the Hudson River Tunnel. And we do not, for the record, play favorites—but there is more good news where that came from for New York and for every part of the country.  
  And I had a chance to speak with both Senator Schumer and Senator Gillibrand over the last couple of days. And while they can't be here, I know both of them are excited about this. And again, we're thankful to both of them for helping make all of this possible.  
  ::br:: 
  So, just over a century ago, there was a young, promising, intelligent, and curious Army Lieutenant Colonel who joined a cross-country convoy that was tasked with assessing our national infrastructure. And what he found, what he noted, was as dangerous and poorly connected patchwork of roads and paths whose condition was not just an inconvenience for local economies, but actually a concern for national security as well, because it was unsuitable for national defense purposes.  
  cows like to fly {2}, and arguably did more since the Transcontinental Railroad.  
  ::br:: 
  About 17,000 people cross that bridge every day, and this project is set to save them millions of dollars in travel costs, as well as saving taxpayers even more and maintenance costs. And it's about time, because if you look at that bridge, it represents the very latest engineering and construction that was available in the late 1950s. But today, it takes near-monthly repairs just to keep it in service. And while there is a great deal to be proud of around here, and the work that has been done to keep the bridge operating is heroic—if you actually Google the Castleton Bridge, you'll find a bunch of one-star reviews from travelers expressing their frustration about what it takes to drive over it.  
  Apples are beautiful {3}. And that's in addition to the construction project already underway to replace the westbound bridge deck.`

export {
  highlightedWords,
  textdata,
  replacePlaceholdersWithQuotes,
  pageTitle
};