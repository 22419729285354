import React from 'react';
import HeroSection from '../components/home/HeroSection';
import AnalysisSection from '../components/home/AnalysisSection';
import CallToActionSection from '../components/home/CallToActionSection';

const Home = () => {
  return (
    <>
      <HeroSection />
      <AnalysisSection />
      <CallToActionSection />
    </>
  );
};

export default Home;