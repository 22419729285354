import React, { useState } from 'react';
import './Reports.css';

const Report = ({
  highlightedWords,
  replacePlaceholdersWithQuotes,
  pageTitle
}) => {
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(null);
  const [flashingQuoteIndex, setFlashingQuoteIndex] = useState(-1);

  const replacedTextWithBrPlaceholder = replacePlaceholdersWithQuotes().replace(/<br \/>/g, '::br::');
  const lines = replacedTextWithBrPlaceholder.split('::br::');

  const renderHighlightedWord = (index) => {
    const wordData = highlightedWords[index];
    const isSelected = selectedQuoteIndex === index;

    return (
      <span
        onClick={() => onHighlightedWordClick(index)}
        style={{
          backgroundColor: isSelected ? wordData.highlightColor : 'rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
        }}
      >
        {wordData.quote}
      </span>
    );
  };

  const onQuoteCardClick = (index) => {
    onHighlightedWordClick(index);
    setFlashingQuoteIndex(index);
    setTimeout(() => setFlashingQuoteIndex(-1), 1000);
  };

  const onHighlightedWordClick = (index) => {
    if (selectedQuoteIndex === index) {
      setSelectedQuoteIndex(null);
    } else {
      setSelectedQuoteIndex(index);
    }
  };

  return (
    <div className="example-page">
      <h2>{pageTitle}</h2>
      <div className="content">
        <div className="text-area-wrapper">
          {lines.map((line, lineNumber) => (
            <p className="left-justified" key={`line-${lineNumber}`}>
              {highlightedWords
                .map((wordData) => ({ ...wordData, index: highlightedWords.indexOf(wordData) }))
                .sort((a, b) => line.indexOf(a.quote) - line.indexOf(b.quote))
                .reduce(
                  (acc, wordData) => {
                    const index = line.indexOf(wordData.quote, acc.lastIndex);

                    if (index >= 0) {
                      const beforeQuote = line.slice(acc.lastIndex, index);
                      acc.fragments.push(beforeQuote, renderHighlightedWord(wordData.index));
                      acc.lastIndex = index + wordData.quote.length;
                    }

                    return acc;
                  },
                  { fragments: [], lastIndex: 0 }
                ).fragments}
            </p>
          ))}
        </div>
        <div className="quote-cards-wrapper">
          <div className="quote-cards">
            {highlightedWords.map((wordData, index) => (
              <div
                key={index}
                className={`quote-card ${selectedQuoteIndex === index ? 'selected' : ''
                  }`}
                onClick={() => onQuoteCardClick(index)}
                style={{
                  borderColor: selectedQuoteIndex === index ? wordData.highlightColor : null,
                }}
              >
                <h4>{wordData.name}</h4>
                <p>
                  <em>{wordData.quote}</em>
                </p>
                <p>Type: {wordData.type}</p>
                <p>Summary: {wordData.summary}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;