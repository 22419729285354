import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import NavigationMenu from './components/NavigationMenu';
import NotFound from './pages/NotFound';
import PeteButtigieg from './components/analysis/PeteButtigieg';
import DonaldTrump from './components/analysis/DonaldTrump';
import Ux1bb984 from './components/reports/Ux1bb984';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationMenu />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/analysis/PeteButtigieg" element={<PeteButtigieg />} />
            <Route path="/analysis/DonaldTrump" element={<DonaldTrump />} />
            <Route path="/reports/Ux1bb984" element={<Ux1bb984 />} />
            {/* Add the catch-all route for the 404 page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
