import React from 'react';
import { Link } from 'react-router-dom';
import './Analysis.css';
import {tableData, title} from './PeteButtigiegData';
import Analysis from './Analysis';

const PeteButtigieg = () => {
  return <
    Analysis
      title={title}
      tableData={tableData}
  />;
};

export default PeteButtigieg;
