const tableData = [
    // Add 20 random rows with believable titles, dates, and locations
    { title: 'Secretary Buttigieg Delivers Remarks on Bridge Investments', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-delivers-remarks-bridge-investments-albany-county-new-york', analysisLink: '/reports/Ux1bb984', date: '2023-04-13', location: 'Albany County, New York', loFals: 3, misinformationIndex: 0.7 },
    { title: 'Secretary Buttigieg Delivers Remarks on Reconnecting Communities', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-delivers-remarks-reconnecting-communities-buffalo-new-york', analysisLink: '/reports/Ux1bb984', date: '2023-03-17', location: 'Buffalo, New York', loFals: 5, misinformationIndex: 0.7 },
    { title: 'Secretary Buttigieg Remarks at FAA Safety Summit', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-remarks-faa-safety-summit', analysisLink: '/reports/Ux1bb984', date: '2023-03-15', location: 'McLean, Virginia', loFals: 5, misinformationIndex: 0.7 },
    { title: 'Secretary Buttigieg Remarks at EV Battery Plant Site', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-remarks-ev-battery-plant-site-desoto-kansas', analysisLink: '/reports/Ux1bb984', date: '2023-02-27', location: 'DeSoto, Kansas', loFals: 5, misinformationIndex: 0.7 },
    { title: 'Secretary Buttigieg Remarks at U.S. Conference of Mayors 91st Winter Meeting', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-remarks-us-conference-mayors-91st-winter-meeting-washington-dc', analysisLink: '/reports/Ux1bb984', date: '2023-01-20', location: 'Washington, DC', loFals: 3, misinformationIndex: 0.7 },
    { title: 'Secretary Buttigieg Remarks at Worldport', titleUrl: 'https://www.transportation.gov/briefing-room/secretary-buttigieg-remarks-worldport-louisville-kentucky', analysisLink: '/reports/Ux1bb984', date: '2022-12-06', location: 'Louisville, Kentucky', loFals: 3, misinformationIndex: 0.3 },
  ];

  const title = "Pete Buttigieg";
  
  export {title, tableData}