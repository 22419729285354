import React, { useState, useEffect } from 'react';

const NotFound = () => {
  const [text, setText] = useState('');
  const message = 'Inn the future, all 404 pages will provide a beautiful insight about your life instead of a message like ';

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < message.length) {
        setText((prevText) => prevText + message[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 85); // Adjust the typing speed by changing this value (in milliseconds)
  
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
      <p>{text}</p>
    </div>
  );
};

export default NotFound;