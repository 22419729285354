import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const CallToActionSection = () => {
  return (
    <section className="cta-section">
      <h2>Interested in hearing more?</h2>
      <p>Discover the full potential of ChatGPT for your business or project today!</p>
      <Link to="https://forms.gle/LmJ7Y5PsBn399S3v9" className="cta-btn">Contact Us</Link>
    </section>
  );
};

export default CallToActionSection;
