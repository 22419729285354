import React, { useState } from 'react';
import './Reports.css';
import {
  highlightedWords,
  replacePlaceholdersWithQuotes,
  pageTitle
} from './data/dataUx1bb984';
import Report from './Report';

const Ux1bb984 = () => {
  return <
    Report
      highlightedWords={highlightedWords}
      replacePlaceholdersWithQuotes={replacePlaceholdersWithQuotes}
      pageTitle={pageTitle}
  />;
};
export default Ux1bb984;