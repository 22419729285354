import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavigationMenu.css';

const NavigationMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="navbar">
      {isMobile && (
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      )}
      <nav className={isMobile ? (menuOpen ? 'nav-open' : 'nav-closed') : 'nav-desktop'}>
        <ul>
          <li>
            <Link to="/" onClick={isMobile ? toggleMenu : undefined}>Home</Link>
          </li>
          <li>
            <Link to="/login" onClick={isMobile ? toggleMenu : undefined}>Login</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default NavigationMenu;
